import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-4">
                <p>
                    <strong>Després de l'èxit de l'any passat</strong>, on més de 280 alumnes van
                    participar en el simulacre de psicotècnics en directe a través de ZOOM, arriba
                    una <strong>nova edició encara més completa</strong>.
                </p>

                <p>
                    <strong>Temari i psicotècnics</strong>
                </p>

                <p>El simulacre constarà de:</p>
                <ol>
                    <li>
                        <strong>Simulacre de psicotècnics de nivell:</strong> disponible
                        immediatament al reservar el simulacre a través del campus.
                    </li>
                    <li>
                        <strong>80 exercicis de psicotècnics:</strong> d'acord amb els oficials i
                        dissenyats pel nostre equip de docents.
                    </li>
                    <li>
                        <strong>30 preguntes de temari:</strong> basades en la guia Mossos.
                    </li>
                </ol>

                <p>
                    <strong>Pren nota:</strong> el diumenge <strong>9 de febrer de 2025</strong>, de{" "}
                    <strong>18.00 a 20.00 h</strong>.
                </p>

                <p>
                    Dos dies abans de la connexió en directe, rebràs els dos exàmens per imprimir i
                    tenir-los preparats. El mateix dia, realitzarem els dos exàmens amb temps
                    cronometrat, com en la prova oficial.
                </p>

                <p>
                    <strong>En acabar:</strong> explicarem en detall els{" "}
                    <strong>80 exercicis de psicotècnics</strong>. Totes les preguntes han estat
                    analitzades i seleccionades pel nostre equip de docents, assegurant que els
                    simulacres són d'alt nivell.
                </p>

                <p>
                    <strong>Campus i gravacions:</strong> disponibles fins al dia de l'examen
                    oficial.
                </p>

                <p>
                    <strong>Comença l'any amb bon peu i posa’t a prova!</strong>
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Comença el 2025 preparat i amb avantatge: participa en el simulacre de psicotècnics en directe més complet! Un entrenament realista amb temari i exercicis dissenyats per experts. Reserva la teva plaça i assoleix l'èxit!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
